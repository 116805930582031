import React from "react";
import { Link, useLocation } from "react-router-dom";

export const Navigation = () => {
  const location = useLocation();

  React.useEffect(() => {
    // Scroll to the correct section if there's a hash in the URL
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a
            className="navbar-brand page-scroll"
            href="/"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              src={process.env.PUBLIC_URL + "/img/logo.png"}
              alt="logo"
              style={{ maxWidth: "300px", maxHeight: "60px" }}
            />
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <Link to="/#services" className="page-scroll">
                Services
              </Link>
            </li>
            <li>
              <Link to="/#solution" className="page-scroll">
                OP-ST Solution
              </Link>
            </li>
            <li>
              <Link to="/#compliance" className="page-scroll">
                Compliance
              </Link>
            </li>
            <li>
              <Link to="/#about" className="page-scroll">
                About Us
              </Link>
            </li>
            <li>
              <Link to="/#contact" className="page-scroll">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
