import React, { useState } from "react";
import ServiceTemplate from "../template/ServiceTemplate";
import { Link } from "react-router-dom";
import "./css/rackinspectionservice.css";

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faq-item">
      <button 
        onClick={() => setIsOpen(!isOpen)}
        className="faq-question"
        aria-expanded={isOpen}
      >
        <span>{question}</span>
        <span className="faq-arrow">
          {isOpen ? "−" : "+"}
        </span>
      </button>
      {isOpen && (
        <div className="faq-answer">
          {answer}
        </div>
      )}
    </div>
  );
};

const RackInspectionService = () => {
  return (
    <ServiceTemplate>
      <div className="rack-inspection-page">
        {/* Title Section */}
        <div className="title-section">
          <h1>Rack Inspection Services</h1>
          <h2>Elevate Your Warehouse Safety Standards</h2>
        </div>

        {/* Content Section with Image */}
        <div className="content-section">
          <div className="content-wrapper">
            <div className="content-text">
              <p>
              Our specialized inspection services go beyond a basic assessment, providing a meticulous level of detail that directly addresses the safety, and compliance needs of your warehouse. With a dedicated team of experts and a commitment to the highest standards, we ensure your racking systems are not just meeting regulations but are optimized for the best possible performance. Partner with Optim-Structure for a safer, more efficient operation that keeps your business running smoothly.
              </p>
              <div className="quoteButton">
                <button 
                  className="btn-primary" 
                  onClick={() => {
                    document.querySelector('#contact').scrollIntoView({ behavior: 'smooth' });
                  }}
                >
                  Request a Quote
                </button>
              </div>
            </div>
            <div className="content-image">
              <img
                src="/img/rack_inspections_1.jpg"
                alt="Rack Inspection"
              />
            </div>
          </div>
        </div>



      <div id="section-homemade-template-gr">
      <div className="container">
        <div className="row align-items-center align-items-stretch">
          
          {/* Left Column: Image */}
          <div className="col-12 col-md-6">
            <div className="about-image-container">
              <img 
                src="/img/rack_inspections_3.jpg" 
                alt="About Us" 
                className="img-fluid about-image" 
              />
            </div>
          </div>
          {/* Right Column: About Us Text */}
          <div className="col-12 col-md-6">
            <div className="about-text">
              <h2>What to Expect from Our Inspection Services
              </h2>
              <div className="list-style row">
                <div className="list-archetype">
                  <ul>
                  <li>Each damage is assigned a trackable barcode number, and detailed photos are taken of each item. Our extensively detailed inspection reports provide individual severity ratings for each damage, clearly outlining whether repairs or replacements are necessary.</li><br/>
                  <li>All inspection reports are meticulously reviewed and sealed by Optim-Structure’s professional engineers, ensuring that the recommendations meet the highest standards of accuracy and safety.</li>
                  </ul>
                </div>

              </div>
            </div>
            
          </div>
          
        </div>
      </div>
    </div>

    <div id="section-homemade-template">
      <div className="container">
        <div className="row align-items-center align-items-stretch">
          
          {/* Left Column: Image */}

          {/* Right Column: About Us Text */}
          <div className="col-12 col-md-6">
            <div className="about-text">
              <h2>Why Choose Optim-Structure for Your Inspections

              </h2>
              <div className="list-style row">
                <div className="list-archetype">
                  <ul>
                  <li><b>Certified Expertise</b>: Our inspectors bring years of industry experience and adhere to the latest safety standards, ensuring that every inspection is thorough and accurate.</li><br/>
                  <li><b>Actionable Insights</b>: We don’t just identify problems; we provide clear, practical solutions tailored to your specific needs.
                  </li><br/>
                  <li><b>Proactive Safety Measures</b>: Regular inspections help prevent costly repairs, reduce risks, and keep your operations running efficiently.
                  </li>
                  </ul>
                </div>

              </div>
            </div>
            
          </div>
          <div className="col-12 col-md-6">
            <div className="about-image-container">
              <img 
                src="/img/rack_inspections_4.jpg" 
                alt="About Us" 
                className="img-fluid about-image" 
              />
            </div>
          </div>
        </div>
      </div>
    </div>


        {/* <div className="expect-section">
          <div className="">
            <div className="expect-content">
              <div className="expect-details">
                <div className="">
                
                  <div className="expect-row1">
                    <div className="expect-text-left">
                      <h3>What to Expect from Our Inspection Services</h3>
                      <div className="why-choose-list">
                        <ul className="expect-list">
                          <li>Each damage is assigned a trackable barcode number, and detailed photos are taken of each item. Our extensively detailed inspection reports provide individual severity ratings for each damage, clearly outlining whether repairs or replacements are necessary.</li><br/>
                          <li>All inspection reports are meticulously reviewed and sealed by Optim-Structure’s professional engineers, ensuring that the recommendations meet the highest standards of accuracy and safety.</li>
                        </ul>

                    <div className="expect-images">
                      <img 
                        src="/img/about1.png" 
                        alt="Expect Image 1" 
                        className="expect-image-1" 
                      />
                    </div>
                  </div>

                  <div className="expect-row2">
                    <div className="expect-images">
                      <img 
                        src="/img/homephoto.png" 
                        alt="Expect Image 1" 
                        className="expect-image-2" 
                      />
                    </div>
                    <div className="expect-text-right">
                      <span className="expect-number">02</span>
                      <h3>Why Choose Optim-Structure for Your Inspections</h3>

                        <div className="why-choose-list">
                          <ul>
                            <li><b>Certified Expertise</b>: Our inspectors bring years of industry experience and adhere to the latest safety standards, ensuring that every inspection is thorough and accurate.</li><br/>
                            <li><b>Actionable Insights</b>: We don’t just identify problems; we provide clear, practical solutions tailored to your specific needs.Proactive Safety Measures: Regular inspections help prevent costly repairs, reduce risks, and keep your operations running efficiently.</li><br/>
                            <li><b>Proactive Safety Measures</b>: Regular inspections help prevent costly repairs, reduce risks, and keep your operations running efficiently.</li>
                          </ul>
                        </div>
                    </div>
                  </div>



                </div>
              </div>
            </div>
          </div>
        </div> */}








        {/* FAQ Section */}
        <div className="faq-section">
          <div className="section-container">
            <h2>FAQ: Frequently Asked Questions</h2>
            <FAQItem
              question="Why are rack inspections crucial to my business?"
              answer="Rack inspections are essential for maintaining a safe working environment, protecting your employees, and avoiding operational disruptions. Regular inspections help identify potential risks before they become major issues, ensuring your racking systems remain compliant and reliable."
            />
            <FAQItem
              question="How frequently should inspections be conducted?"
              answer="While internal visual checks can be done regularly, it’s recommended that a full inspection by a professional engineer is conducted annually. The frequency can vary based on factors like load, traffic, and the history of rack impacts; we’ll help you determine the best schedule for your facility."
            />
            <FAQItem
              question="What happens if compliance issues are found?"
              answer="If compliance or safety issues are detected, we assess the severity of each damage, providing a clear and prioritized action plan. Whether it's minor adjustments, repairs, or part replacements, we offer practical guidance to ensure your systems are restored to full compliance and safety."
            />
            <FAQItem
              question="How does Optim-Structure assess the severity of the damages that are found?"
              answer="All damages are rated on a colour-coded scale (green, yellow, red) and a numerical scale from 1 (minor or cosmetic) to 9 (major or highly dangerous), allowing you to understand the urgency of each issue and act accordingly."
            />
            <FAQItem
              question="Does my pallet racking have to be inspected?"
              answer="Yes, Canadian regulations require all facilities with pallet racking to conduct regular inspections. Regular inspections not only meet regulatory requirements but also help to ensure the ongoing safety of your warehouse environment."
            />
            <FAQItem
              question="Can’t I conduct an internal inspection?"
              answer="Routine monthly inspections can be performed by your internal staff, but full annual inspections should be conducted by external experts. According to CSA A344 standards, these experts must specialize in pallet racking, have structural engineering knowledge, and operate under the supervision of a Professional Engineer (P.Eng)."
            />
            <FAQItem
              question="How long will the Optim-Structure expert be on-site?"
              answer="The duration varies depending on the size of the facility. Inspections can take from a few hours for small facilities to several days for larger ones. In most cases, a single day is sufficient."
            />
            <FAQItem
              question="Do we risk being shut down during the inspection?"
              answer="No, inspections do not require a shutdown. However, in cases of severe damage, specific bays may need to be unloaded and taken out of service temporarily until repairs are completed."
            />
            <FAQItem
              question="Do I have to stop work during the inspection?"
              answer="No, your business can continue to operate normally while the inspection takes place. Our team works efficiently to ensure minimal disruption to your operations."
            />
            <FAQItem
              question="How much does an inspection cost?"
              answer="Expert inspection costs vary based on the size of the facility and the condition of the racking."
            />
            <FAQItem
              question="Does Optim-Structure work at heights?"
              answer="While most inspections are conducted from the floor, Optim-Structure has staff trained to work at heights when necessary. For areas requiring access from above, we employ specialized rope-access personnel to ensure comprehensive inspections."
            />
            <FAQItem
              question="Will the inspection report be stamped by a P. Eng (Professional Engineer)?"
              answer="Yes, all reports are thoroughly reviewed and sealed by a Professional Engineer, who assumes full responsibility for the report's content, ensuring accuracy and compliance with industry standards."
            />
          </div>
        </div>

        {/* Practical Training Section - Redesigned */}
        <div className="practical-training-section">
          <div className="section-container">
            <div className="training-content">
              <div className="training-text">
                <h2>Practical Training for Conducting In-House Inspections
                </h2>
                <div className="training-details">
                  <p>
                  If you’ve attended our virtual training, our Site Services Technician can conduct a practical learning exercise during your Expert Inspection. This allows you to ask questions, discuss inspection findings, and refine your in-house inspection skills. This hands-on experience is complimentary for students who have completed our virtual training.
                  </p>
                  {/* <ul>
                    <li>Practical on-site learning during Expert Inspections</li>
                    <li>Direct interaction with our Site Services Technician</li>
                    <li>Real-time discussion of inspection findings</li>
                    <li>Refinement of in-house inspection techniques</li>
                  </ul> */}
                  {/* <div className="training-note">
                    <span className="note-highlight">Complimentary Access</span>
                    <p>This hands-on experience comes at no additional cost for students who have completed our virtual training program.</p>
                  </div> */}
                </div>
              </div>
              <div className="op-st-button-container">
                <div className="training-cta">
                  <Link to="/training" className="btn-training">
                    Discover OP-ST Training Programs
                    <span className="arrow-icon">→</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ServiceTemplate>
  );
};

export default RackInspectionService;