import React from "react";
import ServiceTemplate from "../template/ServiceTemplate";
import "./css/styles.css";
import { Link } from "react-router-dom";
import "./css/FirstColumnPage.css";


const TrainingCardDetails = ({ title, price, includes, buttonLabel, to }) => (
  <div className="training-card-details">
    <div className="training-card-content">
      <div className="training-card-header">
        <h3>{title}</h3>
      </div>
      <div className="price-tag">{price}</div>
      <p className="includes">{includes}</p>
      <button className="btn-primary"
                  onClick={() => {
                    document.querySelector('#contact').scrollIntoView({ behavior: 'smooth' });
                  }}>
              Contact Us
            </button>

      
    </div>
  </div>
);


const ThirdColumnPage = () => {
  return (
    <ServiceTemplate>
      {/* Title Section */}
      <div className="title-section">
        <h1>1/2 DAY - VIRTUAL - PUBLIC SESSION</h1>
      </div>

      {/* Main Content Section */}
      <div id="section-homemade-template">
      <div className="container">
        <div className="row align-items-center align-items-stretch">
          
          {/* Left Column: Image */}
          <div className="col-12 col-md-6">
            <div className="training-details-card">
            <TrainingCardDetails
                title="1/2 DAY - VIRTUAL/ON-SITE - INSPECTING RACKS"
                price="1250$ CAD"
                includes="Includes: Up to 5 participants (200$ per additional attendee)"

                buttonLabel="Contact Us"
              />
            </div>
          </div>
          {/* Right Column: About Us Text */}
          <div className="col-12 col-md-6">
            <div className="about-text">
              <h3>The half-day session, “Inspecting Your Steel Storage Racks,” focuses on:
              </h3>
              <div className="list-style row">
                <div className="list-archetype">
                <div className="col-lg-12 col-sm-12">

                    <ul>

                      <li>Understanding the CSA A344-24 Guideline (User Guide for Steel Storage Racks) and key terminology</li>
                      <li>Reviewing past rack collapses to learn from real cases</li>
                      <li>Covering the fundamentals of rack systems and setting clear expectations</li>
                      <li>Examining incidents of accidents and fatalities</li>
                      <li>Understanding the standard’s requirements for safety</li>
                      <li>Recognizing overload and identifying damage</li>
                      <li>Classifying and documenting damage accurately</li>
                      <li>Exploring special knowledge topics for advanced insight</li>
                      <li>Conducting virtual rack inspections</li>
                      <p><b>Participants will receive a certificate upon completion</b>.</p>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
            
          </div>
          
        </div>
      </div>
    </div>
    </ServiceTemplate>
  );
};

export default ThirdColumnPage;