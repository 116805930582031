import React, { useState } from "react";
import ServiceTemplate from "../template/ServiceTemplate";
import "./css/management.css";

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faq-item">
      <button 
        onClick={() => setIsOpen(!isOpen)}
        className="faq-question"
        aria-expanded={isOpen}
      >
        <span>{question}</span>
        <span className="faq-arrow">
          {isOpen ? "−" : "+"}
        </span>
      </button>
      {isOpen && (
        <div className="faq-answer">
          {answer}
        </div>
      )}
    </div>
  );
};

const ManagementPage = () => {
  return (
    <ServiceTemplate>
      {/* Title Section */}
      <div className="title-section">
        <h1>Project Management</h1>
        <h2>Bringing Your Vision to Life</h2>
      </div>

      {/* Content Section */}
      <div className="content-section">
        <div className="content-wrapper">
          <div className="content-text">
            <h3 className="subheader-management">
              Looking for racks but don’t know where to start?
             </h3>
            <p><br />
            At <b>Optim Structure</b>, we take your ideas and turn them into reality. Whether you're launching a new warehouse or upgrading an existing one, we handle every aspect of your racking system project—from the initial concept to full execution. Our team of experts ensures that your warehouse operates at peak efficiency, with racking systems designed to optimize space, safety, and productivity.
            </p>
            <p>By trusting <b>Optim Structure</b> to manage your project, you’re not just investing in racks—you’re investing in the peace of mind that comes from knowing every detail is handled by professionals who prioritize your business's success.</p>
            <button 
              className="btn-primary"
              onClick={() => {
                document.querySelector('#contact').scrollIntoView({ behavior: 'smooth' });
              }}
            >
              Contact Us for More Info
            </button>
          </div>
          <div className="content-image2">
            <img
              src="/img/project_management_1.jpg"
              alt="Project Management"
            />
          </div>
        </div>
      </div>


      <div id="section-homemade-template-gr">
      <div className="container">
        <div className="row align-items-center align-items-stretch">
          
          {/* Left Column: Image */}
          <div className="col-12 col-md-6">
            <div className="about-image-container">
              <img 
                src="/img/project_management_2.png" 
                alt="About Us" 
                className="img-fluid about-image2" 
              />
            </div>
          </div>
          {/* Right Column: About Us Text */}
          <div className="col-12 col-md-6">
            <div className="about-text">
              <h2>Why Choose Optim-Structure for Your Inspections

              </h2>
              <div className="list-style row">
                <div className="list-archetype">
                <div className="col-lg-6 col-sm-6">

                    <ul>
                      <br/>
                      <li>Warehouse Visit</li>
                      <li>Coordination Meetings</li>
                      <li>Designing an Efficient Plan</li>
                      <li>Determining Best Rack Type</li>
                    </ul>
                  </div>
                  <div className="col-lg-6 col-sm-6">
                  <ul>
                    <br/>
                    <li>Procurement and Installation</li>
                    <li>Capacity Report</li>
                    <li>Inspection Report</li>
                    <li>OP-ST Training</li>
                  </ul>
                  </div>
                </div>

              </div>
            </div>
            
          </div>
          
        </div>
      </div>
    </div>
      {/* FAQ Section */}
      <div className="faq-section">
        <div className="section-container">
          <h2>FAQ: Frequently Asked Questions</h2>
          <FAQItem
            question="How long does it take to complete a racking project?"
            answer="The timeline varies depending on the size and complexity of the project."
          />
          <FAQItem
            question="Do you handle the entire process, including installation?"
            answer="Yes, we manage everything from design and procurement to installation, ensuring a seamless experience from start to finish."
          />
          <FAQItem
            question="What kind of racking systems do you recommend?"
            answer="We assess your business type and operational needs to recommend the best racking system, ensuring maximum efficiency, safety, and space optimization."
          />
          <FAQItem
            question="Will my team receive training on how to use and maintain the racks?"
            answer="Absolutely! Our OP-ST Training program ensures your staff is fully equipped with the knowledge to maintain and operate the racking systems safely and efficiently."
          />
        </div>
      </div>
    </ServiceTemplate>
  );
};

export default ManagementPage;
