import React from "react";
import { Link } from "react-router-dom";
import './components-css/about.css';

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row align-items-center align-items-stretch">
          
          {/* Left Column: Image */}
          <div className="col-12 col-md-6">
            <div className="about-image-container">
              <img 
                src="/img/about1.png" 
                alt="About Us" 
                className="img-fluid about-image" 
              />
            </div>
          </div>

          {/* Right Column: About Us Text */}
          <div className="col-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>Why Choose Us?</h3>
              <div className="list-style row">
                <div className="col-lg-6 col-sm-6">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
              <Link to="/about" className="btn btn-custom btn-lg">
                Learn More
              </Link>


            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
