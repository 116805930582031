import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { InfiniteScrollBanner } from "./components/infinitescroll";
import { Opst } from "./components/opst";
import SmallBanner from "./components/smallbanner";
import { Compliance } from "./components/compliance";
import RackInspectionService from "./pages/RackInspectionService";

import CapacityPage from "./pages/CapacityPage";
import TrainingPage from "./pages/TrainingPage";
import FirstColumnPage from "./pages/FirstColumnPage";
import SecondColumnPage from "./pages/SecondColumnPage";
import ThirdColumnPage from "./pages/ThirdColumnPage";
import FourthColumnPage from "./pages/FourthColumnPage";
import AutomationPage from "./pages/Automation";
import DesignPage from "./pages/Design";
import ManagementPage from "./pages/Management";
import SolutionPage from "./pages/Solution";
import CompliancePage from "./pages/Compliance";
import AboutUsPage from "./pages/About";

import ScrollToTop from "./components/ScrollToTop"; 

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const LandingPage = ({ data }) => (
  <div>
    <Navigation />
    <Header data={data.Header} />
    <Services data={data.Services} />
    <InfiniteScrollBanner data={data.Banner} />
    <Opst data={data.Opst} />
    <SmallBanner />
    <Compliance data={data.Compliance} />
    <About data={data.About} />
    <Contact data={data.Contact} />
  </div>
);

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <ScrollToTop /> 
      <Routes>
        <Route path="/" element={<LandingPage data={landingPageData} />} />
        <Route path="/racking-inspection" element={<RackInspectionService />} />
        <Route path="/capacity" element={<CapacityPage />} />
        <Route path="/automation" element={<AutomationPage />} />
        <Route path="/design" element={<DesignPage />} />
        <Route path="/management" element={<ManagementPage />} />
        <Route path="/training" element={<TrainingPage />} />
        <Route path="/first-training" element={<FirstColumnPage />} />
        <Route path="/second-training" element={<SecondColumnPage />} />
        <Route path="/third-training" element={<ThirdColumnPage />} />
        <Route path="/fourth-training" element={<FourthColumnPage />} />
        <Route path="/solution" element={<SolutionPage />} />
        <Route path="/compliance" element={<CompliancePage />} />
        <Route path="/about" element={<AboutUsPage />} />
      </Routes>
    </div>
  );
};

export default App;
