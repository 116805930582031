import React, { useState } from "react";
import ServiceTemplate from "../template/ServiceTemplate";
import "./css/automation.css";


const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faq-item">
      <button 
        onClick={() => setIsOpen(!isOpen)}
        className="faq-question"
        aria-expanded={isOpen}
      >
        <span>{question}</span>
        <span className="faq-arrow">
          {isOpen ? "−" : "+"}
        </span>
      </button>
      {isOpen && (
        <div className="faq-answer">
          {answer}
        </div>
      )}
    </div>
  );
};

const AutomationPage = () => {
  return (
    <ServiceTemplate>
      {/* Title Section */}
      <div className="title-section">
        <h1>Racking Automation</h1>
        <h2>Optimizing Your Warehouse with Cutting-Edge Solutions</h2>
      </div>

      {/* Content Section */}
      <div className="content-section">
        <div className="intro-wrapper">
          <div className="first-paragraph">
            <p>
              Automated racking systems are revolutionizing warehouse operations. Designed to optimize space, improve accessibility, and boost efficiency, these systems use advanced technology to automate the storage and retrieval of goods. By integrating automation into your racking system, you can reduce labour costs, minimize human error, and maximize storage capacity—all while ensuring smooth, continuous operations.<br></br><br></br>
              Automated racks, such as automated storage and retrieval systems (AS/RS) or pallet shuttles, offer unmatched precision and speed, making them ideal for warehouses with high-volume or fast-moving inventory. These systems are built to streamline your workflow and reduce the time spent managing inventory, helping you achieve a higher level of productivity and efficiency.
              
              <br/><br/>
              <div className="consultation-button-container">
                <button className="consultation-button"
                  onClick={() => {
                    document.querySelector('#contact').scrollIntoView({ behavior: 'smooth' });
                  }}>
                  Request Consultation
                </button>
              </div>
              
            </p>
            

          </div>
          <div className="content-image4">
            <img
              src="/img/automation_1.png"
              alt="Automated Racking Systems"
            />
          </div>
        </div>


      </div>


      <div id="section-homemade-template-gr">
      <div className="container">
        <div className="row align-items-center align-items-stretch">
          
          {/* Left Column: Image */}

          <div className="col-12 col-md-6">
            <div className="about-image-container">
              <img 
                src="/img/automation_2.png" 
                alt="About Us" 
                className="img-fluid about-image" 
              />
            </div>
          </div>
          {/* Right Column: About Us Text */}
          <div className="col-12 col-md-6">
            <div className="about-text">
              <h2>What to Expect from Our Service</h2>
              {/* <p>At <b>Optim Structure</b>, we don’t just design your automated racking system; we partner with you to ensure it’s tailored to your exact needs. Here’s what you can expect from our racking automation service:</p> */}
              <div className="list-style row">
                <div className="list-archetype">
                  <ul>
                  <li><b>Warehouse Visit</b>: We conduct an on-site visit to assess your current setup and identify the best approach for implementing an automated system.</li><br/>
                  <li><b>Coordination Meetings</b>: Our team works closely with you through every phase, ensuring your goals and needs are fully understood and incorporated into the design.</li><br/>
                  <li><b>Warehouse Plan</b>: We develop a customized plan for automating your racking system, ready for your approval and a detailed quote.</li><br/>
                  <li><b>Optional Quote</b>: If needed, we provide comprehensive support for both the automated racking system and its installation.</li>
                </ul>
                </div>

              </div>
            </div>
            
          </div>

        </div>
      </div>
    </div>

    <div id="section-homemade-template">
      <div className="container">
        <div className="row align-items-center align-items-stretch">
          
          {/* Left Column: Image */}
          {/* Right Column: About Us Text */}
          <div className="col-12 col-md-6">
            <div className="about-text">
              <h2>Key Benefits of Automation</h2>
              <div className="list-style row">
                <div className="list-archetype">
                  <p>By choosing <b>Optim Structure</b>, you’re not just investing in automation—you’re investing in the long-term success of your warehouse. With increased productivity, reduced costs, and streamlined operations, our racking automation solutions ensure your business stays ahead of the curve in today’s competitive landscape.</p>
                </div>

              </div>
            </div>
            
            
          </div>
          <div className="col-12 col-md-6">
            <div className="about-image-container">
              <img 
                src="/img/automation_3.jpg" 
                alt="About Us" 
                className="img-fluid about-image" 
              />
            </div>
          </div>
        </div>
      </div>
    </div>

      
      {/* <div className="expect-section">
        <div className="section-container">
          <div className="expect-content">
            <h2>What to Expect from Our Service</h2>

            <p className="expect-p">At <b>Optim Structure</b>, we don’t just design your automated racking system; we partner with you to ensure it’s tailored to your exact needs. Here’s what you can expect from our racking automation service:</p>
            
            <div className="expect-num-list">
              <div className="expect-num-list-item">
                <span className="to-expect-num">01</span>
                <h3>Warehouse Visit</h3>
              </div>
              <p>We conduct an on-site visit to assess your current setup and identify the best approach for implementing an automated system.</p>
            </div>

            <div className="expect-num-list">
              <div className="expect-num-list-item">
                <span className="to-expect-num">02</span>
                <h3>Coordination Meetings</h3>
              </div>
              <p>Our team works closely with you through every phase, ensuring your goals and needs are fully understood and incorporated into the design.</p>
            </div>

            <div className="expect-num-list">
              <div className="expect-num-list-item">
                <span className="to-expect-num">03</span>
                <h3>Warehouse Plan</h3>
              </div>
              <p>We develop a customized plan for automating your racking system, ready for your approval and a detailed quote.</p>
            </div>

            <div className="expect-num-list">
              <div className="expect-num-list-item">
                <span className="to-expect-num">04</span>
                <h3>Optional Quote</h3>
              </div>
              <p>If needed, we provide comprehensive support for both the automated racking system and its installation.</p>
            </div>

          </div>
        </div>
      </div>

      <div className="choose-us-section">
        <div className="section-container">
          <div className="key-benefits">
            <h2>Key Benefits of Automation</h2>

            <p>By choosing <b>Optim Structure</b>, you’re not just investing in automation—you’re investing in the long-term success of your warehouse. With increased productivity, reduced costs, and streamlined operations, our racking automation solutions ensure your business stays ahead of the curve in today’s competitive landscape.</p>

            
          </div>
        </div>
      </div> */}

      {/* FAQ Section */}
      <div className="faq-section">
        <div className="section-container">
          <h2>FAQ: Frequently Asked Questions</h2>
          <FAQItem
            question="How long does it take to design and install an automated racking system?"
            answer="The timeline depends on the size and complexity of your warehouse. Our team will provide a detailed project timeline during the consultation phase."
          />
          <FAQItem
            question="How do automated racking systems impact long-term operational costs?"
            answer="Automated racking systems significantly reduce labour costs, improve accuracy, and boost efficiency. These factors lead to long-term savings and faster returns on investment."
          />
          <FAQItem
            question="Can automated racks be customized for different types of warehouses?"
            answer="Yes, automated racking systems can be fully customized to fit your warehouse's specific layout, inventory needs, and operational flow."
          />
          <FAQItem
            question="What are the key benefits of switching to an automated racking system?"
            answer="Key benefits include increased storage capacity, improved accuracy in inventory management, faster retrieval times, and reduced labour costs, making your operations more efficient and scalable."
          />
        </div>
      </div>
    </ServiceTemplate>
  );
};

export default AutomationPage;
