import React from "react";
import { Link } from "react-router-dom";
import ServiceTemplate from "../template/ServiceTemplate";
import "./css/solution.css";

const SolutionPage = () => {
  return (
    <ServiceTemplate>
      {/* Title Section */}
      <div className="title-section">
        <h1>OP-ST Solution</h1>
        <h2>A Comprehensive Approach to Rack Management</h2>
      </div>

      {/* First Content Section */}
      <div className="content-section">
      <div className="content-image6">
            <img
              src="/img/opst_sol_1.png"
              alt="OP-ST Solution Overview"
            />
          </div>
        <div className="content-wrapper">
          
          <div className="content-text">
            <p>
            The <b>OP-ST Solution</b> offers businesses a clear and structured approach to managing their existing pallet racks. Through a strategic cycle of essential services, it ensures your warehouse operations not only meet compliance standards but operate at peak efficiency and safety year-round. At Optim Structure, we don’t just provide services—we empower our clients with the tools and expertise they need to take full control of their facilities. 
            </p>
          </div>

          <div className="content-text">
            <p>The <b>OP-ST Solution</b> serves as your roadmap to proactive rack management, designed to help you prevent risks before they arise, maximize operational efficiency, and streamline safety processes. By adopting this solution, you’re not just maintaining compliance—you’re investing in the long-term success and security of your warehouse.
            </p>
          </div>

        </div>
      </div>

      <div id="section-solution-template-gr">
      <div className="container">
        <div className="opstsol-mobile row align-items-center align-items-stretch">
          
          {/* Left Column: Image */}
          <div className="col-12 col-md-6">
            <div className="about-image-container">
            <div className="content-image7">
            <img
              src="/img/opst_sol_2.jpg"
              alt="Compliance Services"
            />
          </div>
            </div>
          </div>
          {/* Right Column: About Us Text */}
          <div className="col-12 col-md-6">
            <div className="about-text">
              <h2>Expert Rack Inspection</h2>
              <div className="list-style row">
                <div className="list-archetype">
                  <p>Regular inspections ensure your racks are free from damage and compliant with safety standards. Our certified inspectors provide clear recommendations for addressing any issues.</p>
                  <Link to={"/racking-inspection"} className="btn-training">
                    Rack Inspections
                    <span className="arrow-icon">→</span>
                  </Link>
                </div>

              </div>
            </div>
            
          </div>

        </div>
      </div>

    </div>    
    
    <div id="section-solution-template">
      <div className="container">
        <div className="opstsol-mobile row align-items-center align-items-stretch">
          
          {/* Left Column: Image */}

          {/* Right Column: About Us Text */}
          <div className="col-12 col-md-6">
            <div className="about-text">
              <h2>Employee Training</h2>
              <div className="list-style row">
                <div className="list-archetype">
                  <p>Equip your workforce with the knowledge they need to respond to rack safety concerns. Our training programs cover best practices for rack inspections, damage reporting, and corrective actions.</p>
                  <Link to={"/training"} className="btn-training">
                    OP-ST Training
                    <span className="arrow-icon">→</span>
                  </Link>
                </div>

              </div>
            </div>
            
          </div>
          <div className="col-12 col-md-6">
            <div className="about-image-container">
            <div className="content-image7">
            <img
              src="/img/opst_sol_3.jpg"
              alt="Compliance Services"
            />
          </div>
            </div>
          </div>
        </div>
      </div>
    </div>   
    
    <div id="section-solution-template-gr">
      <div className="container">
        <div className="opstsol-mobile row align-items-center align-items-stretch">
          
          {/* Left Column: Image */}
          <div className="col-12 col-md-6">
            <div className="about-image-container">
            <div className="content-image7">
            <img
              src="/img/opst_sol_4.jpg"
              alt="Compliance Services"
            />
          </div>
            </div>
          </div>
          {/* Right Column: About Us Text */}
          <div className="col-12 col-md-6">
            <div className="about-text">
              <h2>Design Optimization</h2>
              <div className="list-style row">
                <div className="list-archetype">
                  <p>Is your warehouse layout optimized for efficiency? We review and refine your racking system designs to maximize space usage and adhere to safety standards.</p>
                  <Link to={"/design"} className="btn-training">
                    Warehouse Design
                    <span className="arrow-icon">→</span>
                  </Link>
                </div>

              </div>
            </div>
            
          </div>

        </div>
      </div>

    </div>    
    
    
    <div id="section-solution-template">
      <div className="container">
        <div className="opstsol-mobile row align-items-center align-items-stretch">
          
          {/* Left Column: Image */}

          {/* Right Column: About Us Text */}
          <div className="col-12 col-md-6">
            <div className="about-text">
              <h2>Capacity Report</h2>
              <div className="list-style row">
                <div className="list-archetype">
                  <p>Understanding your rack's load limits is crucial to preventing overloading. Our capacity reports provide precise calculations to help you comply with safety regulations.</p>
                  <Link to={"/capacity"} className="btn-training">
                    Capacity Reports
                    <span className="arrow-icon">→</span>
                  </Link>
                </div>
              </div>
            </div>
            
          </div>
          <div className="col-12 col-md-6">
            <div className="about-image-container">
            <div className="content-image7">
            <img
              src="/img/opst_sol_5.jpg"
              alt="Compliance Services"
            />
          </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="section-solution-template-gr">
      <div className="container">
        <div className="opstsol-mobile row align-items-center align-items-stretch">
          
          {/* Left Column: Image */}
          <div className="col-12 col-md-6">
            <div className="about-image-container">
            <div className="content-image7">
            <img
              src="/img/opst_sol_6.jpg"
              alt="Compliance Services"
            />
          </div>
            </div>
          </div>
          {/* Right Column: About Us Text */}
          <div className="col-12 col-md-6">
            <div className="about-text">
              <h2>Certification of Repairs</h2>
              <div className="list-style row">
                <div className="list-archetype">
                  <p>After repairs, we certify that your racks comply with safety standards, ensuring a safe environment for all operations.</p>
                  <button className="btn-primary"
                  onClick={() => {
                    document.querySelector('#contact').scrollIntoView({ behavior: 'smooth' });
                  }}>
              Contact Us
            </button>
                </div>

              </div>
            </div>
            
          </div>

        </div>
      </div>

    </div>     

      {/* <div className="expect-section">
        <div className="section-container">
          <div className="expect-content">
            <h2>The Key Components of the OP-ST Solution
            </h2>
            <div className="solution-timeline">
              <div className="timeline-item">
                <span className="expect-number">01</span>
                <div className="timeline-contents">
                  <div className="opst-solution-subheader">
                    <span className="to-expect-num">04</span>
                    <h3>Expert Rack Inspection</h3>
                  </div>
                  <p>Regular inspections ensure your racks are free from damage and compliant with safety standards. Our certified inspectors provide clear recommendations for addressing any issues.</p>
                  <Link to="/racking-inspection">
                    <button className="btn-primary">Learn More</button>
                  </Link>
                </div>
              </div>

              <div className="timeline-item">
                <span className="expect-number">02</span>
                <div className="timeline-content">
                  <h3>Employee Training</h3>
                  <p>Equip your workforce with the knowledge they need to respond to rack safety concerns. Our training programs cover best practices for rack inspections, damage reporting, and corrective actions.</p>
                  <Link to="/training">
                    <button className="btn-primary">Learn More</button>
                  </Link>
                </div>
              </div>

              <div className="timeline-item">
                <span className="expect-number">03</span>
                <div className="timeline-content">
                  <h3>Design Optimization</h3>
                  <p>Is your warehouse layout optimized for efficiency? We review and refine your racking system designs to maximize space usage and adhere to safety standards.</p>
                  <Link to="/design">
                    <button className="btn-primary">Learn More</button>
                  </Link>
                </div>
              </div>

              <div className="timeline-item">
                <span className="expect-number">04</span>
                <div className="timeline-content">
                  <h3>Capacity Report</h3>
                  <p>Understanding your rack's load limits is crucial to preventing overloading. Our capacity reports provide precise calculations to help you comply with safety regulations.</p>
                  <Link to="/capacity">
                    <button className="btn-primary">Learn More</button>
                  </Link>
                </div>
              </div>

              <div className="timeline-item">
                <span className="expect-number">05</span>
                <div className="timeline-content">
                  <h3>Certification of Repairs</h3>
                  <p>After repairs, we certify that your racks comply with safety standards, ensuring a safe environment for all operations.</p>
                  <a href="#contact">
                    <button className="btn-primary">Contact Us</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* CTA Section */}
      <div className="practical-training-section">
        <div className="section-container">
          <div className="training-content">
            <div className="training-text">
              <h2>Don't have existing racks? Let us handle it all</h2>
              <div className="training-details">
                <p>Our team manages every aspect of your racking project, from design to installation. Let Optim Structure lead your warehouse transformation.</p>
              </div>
            </div>
            <div className="training-cta">
              <Link to="/management">
                <button className="btn-training">
                  Project Management
                  <span className="arrow-icon">→</span>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </ServiceTemplate>
  );
};

export default SolutionPage;
