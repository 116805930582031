import React from "react";
import ServiceTemplate from "../template/ServiceTemplate";
import "./css/styles.css";
import { Link } from "react-router-dom";
import "./css/FirstColumnPage.css";


const TrainingCardDetails = ({ title, price, includes, buttonLabel, to }) => (
  <div className="training-card-details">
    <div className="training-card-content">
      <div className="training-card-header">
        <h3>{title}</h3>
      </div>
      <div className="price-tag">{price}</div>
      <p className="includes">{includes}</p>
      <button className="btn-primary"
                  onClick={() => {
                    document.querySelector('#contact').scrollIntoView({ behavior: 'smooth' });
                  }}>
              Contact Us
            </button>

      
    </div>
  </div>
);


const SecondColumnPage = () => {
  return (
    <ServiceTemplate>
      {/* Title Section */}
      <div className="title-section">
        <h1>1/2 DAY - VIRTUAL - PUBLIC SESSION</h1>
      </div>

      {/* Main Content Section */}
      <div id="section-homemade-template">
      <div className="container">
        <div className="row align-items-center align-items-stretch">
          
          {/* Left Column: Image */}
          <div className="col-12 col-md-6">
            <div className="training-details-card">
            <TrainingCardDetails
                title="1/4 DAY - VIRTUAL/ON-SITE - RACK AWARENESS"
                price="700$ CAD"
                includes="Includes: Up to 5 participants (100$ per additional attendee)"

                buttonLabel="Contact Us"
              />
            </div>
          </div>
          {/* Right Column: About Us Text */}
          <div className="col-12 col-md-6">
            <div className="about-text">
              <h3>The two-hour session, “Rack Awareness and Safety,” covers the following key topics:
              </h3>
              <div className="list-style row">
                <div className="list-archetype">
                <div className="col-lg-12 col-sm-12">

                    <ul>

                      <li>How to inspect racks and address identified deficiencies</li>
                      <li>The potential consequences of rack collapses</li>
                      <li>Relevant legislation and regulations</li>
                      <li>Common hazards in rack systems</li>
                      <li>Effective methods for conducting rack inspections</li>
                      <p>While participants will not receive a formal certificate, a letter can be provided upon request, outlining the training content and listing the attendees, along with the date of the session.</p>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
            
          </div>
          
        </div>
      </div>
    </div>
    </ServiceTemplate>
  );
};

export default SecondColumnPage;