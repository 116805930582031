import React from "react";
import { Link } from "react-router-dom";

export const Opst = (props) => {
  return (
    <div id="solution">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xs-12 col-md-5">
            <div className="about-text">
              <h2>The OP-ST Solution</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <div>
                <Link to="/solution" className="btn btn-custom btn-lg">
                  Learn More
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-7">
            <div className="solution-image-container">
              <img
                src="img/opst_sol_1.png"
                className="solution-image"
                alt="Expert Rack Inspection"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
