import React from "react";
import ServiceTemplate from "../template/ServiceTemplate";
import "./css/styles.css";
import { Link } from "react-router-dom";
import "./css/FirstColumnPage.css";


const TrainingCardDetails = ({ title, price, includes, buttonLabel, to }) => (
  <div className="training-card-details">
    <div className="training-card-content">
      <div className="training-card-header">
        <h3>{title}</h3>
      </div>
      <div className="price-tag">{price}</div>
      <p className="includes">{includes}</p>
      <button className="btn-primary"
                  onClick={() => {
                    document.querySelector('#contact').scrollIntoView({ behavior: 'smooth' });
                  }}>
              Contact Us
            </button>

      
    </div>
  </div>
);


const FirstColumnPage = () => {
  return (
    <ServiceTemplate>
      {/* Title Section */}
      <div className="title-section">
        <h1>1/2 DAY - VIRTUAL - PUBLIC SESSION</h1>
      </div>

      {/* Main Content Section */}
      <div id="section-homemade-template">
      <div className="container">
        <div className="row align-items-center align-items-stretch">
          
          {/* Left Column: Image */}
          <div className="col-12 col-md-6">
            <div className="training-details-card">
            <TrainingCardDetails
                title="1/2 DAY - VIRTUAL - PUBLIC SESSION"
                price="249$ CAD"
                includes="Includes: 1 seat"

                buttonLabel="Contact Us"
              />
            </div>
          </div>
          {/* Right Column: About Us Text */}
          <div className="col-12 col-md-6">
            <div className="about-text">
              <h3>The half-day public session, “<b>Inspecting Your Steel Storage Racks</b>”, covers the following topics:


              </h3>
              <div className="list-style row">
                <div className="list-archetype">
                <div className="col-lg-12 col-sm-12">

                    <ul>
                      <br/>
                      <li>Gaining a solid understanding of the CSA A344-24 Guideline (User Guide for Steel Storage Racks) and key terminology</li>
                      <li>Analyzing rack collapses and learning from real-world examples</li>
                      <li>Grasping the essentials of rack systems and setting clear learning goals</li>
                      <li>Reviewing incidents of accidents and fatalities related to racking systems</li>
                      <li>Understanding the requirements outlined in the standard</li>
                      <li>Recognizing signs of rack overload and damage</li>
                      <li>Systematically classifying and recording damage</li>
                      <li>Learning special insights and advanced knowledge topics</li>
                      <li>Practicing virtual inspections of racks</li>
                      <p><b>A certificate will be awarded upon successful completion of the session</b>.</p>
                    </ul>
                  </div>


                </div>

              </div>
            </div>
            
          </div>
          
        </div>
      </div>
    </div>

    <div id="section-homemade-template-grsmall">
      <div className="container">
        <div className="fst-training-txt row align-items-center align-items-stretch">
          
          {/* Left Column: Image */}
          <div className="col-12 col-md-6">
            <p style={{ fontSize: '1.8rem' }}>Steel storage racks are a staple in warehouses, manufacturing plants, and small businesses, but many users assume these systems are safe and capable of handling their loads without issues. However, damage from lift trucks can significantly weaken rack capacity and, if not promptly identified, unloaded, and repaired, may result in a dangerous collapse. This training course is designed to raise awareness among frontline workers about the risks, which range from serious injury or fatalities to product loss, business disruption, legal action, and reputational damage.</p>
          </div>
          {/* Right Column: About Us Text */}
          <div className="col-12 col-md-6">
            <div className="about-text">
              <p>Delivered virtually via Microsoft Teams, the course lasts approximately 3.5 hours. By participating in this half-day session, attendees will learn how to effectively inspect and maintain steel storage racks in accordance with the new CSA A344 User Guide for Steel Storage Racks. The course is customizable to fit your company’s specific requirements, and the instructor tailors the training to the needs of the participants. With small class sizes, every attendee has the opportunity to engage fully, ensuring all questions and concerns are addressed directly by the instructor.</p>
</div>
            
          </div>
          
        </div>
      </div>
    </div>
    </ServiceTemplate>
  );
};

export default FirstColumnPage;