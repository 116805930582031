import React, { useState } from "react";
import { Link } from "react-router-dom";
import ServiceTemplate from "../template/ServiceTemplate";
import "./css/trainingpage.css";


const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faq-item">
      <button 
        onClick={() => setIsOpen(!isOpen)}
        className="faq-question"
        aria-expanded={isOpen}
      >
        <span>{question}</span>
        <span className="faq-arrow">
          {isOpen ? "−" : "+"}
        </span>
      </button>
      {isOpen && (
        <div className="faq-answer">
          {answer}
        </div>
      )}
    </div>
  );
};

const TrainingCard = ({ title, price, includes, buttonLabel, to }) => (
  <div className="training-card">
    <div className="training-card-content">
      <div className="training-card-header">
        <h3>{title}</h3>
      </div>
      <div className="price-tag">{price}</div>
      <p className="includes">{includes}</p>
      <Link to={to} className="btn-training">
        {buttonLabel}
        <span className="arrow-icon">→</span>
      </Link>

      
    </div>
  </div>
);

const TrainingCard2 = ({ title, price, includes, buttonLabel, to }) => (
  <div className="training-card">
    <div className="training-card-content">
      <div className="training-card-header">
        <h3>{title}</h3>
      </div>
      <div className="price-tag">{price}</div>
      <p className="includes">{includes}</p>
      <button className="btn-primary"
                  onClick={() => {
                    document.querySelector('#contact').scrollIntoView({ behavior: 'smooth' });
                  }}>
              Contact Us
            </button>

      
    </div>
  </div>
);


const TrainingPage = () => {
  return (
    <ServiceTemplate>
      {/* Title Section */}
      <div className="title-section">
        <h1>OP-ST Training</h1>
        <h2>Elevating Your In-House Expertise</h2>
      </div>

      {/* Content Section */}
      <div className="content-section">
        <div className="content-wrapper">
          <div className="content-text">
            <p>
              Optim-Structure's expert-led training empowers your team to conduct routine rack inspections, detect risks early, and take immediate action to prevent costly accidents. Covering critical safety protocols and industry compliance, our hands-on sessions equip your staff with the skills to assess damage, prioritize repairs, and ensure long-term safety.
</p><p>
              Certified by Optim-Structure's professional engineers, your team will reduce operational downtime, enhance workplace safety, and shield your business from costly liabilities—making OP-ST Training an essential investment for every warehouse.
            </p>
            <a href="#training-programs" className="btn-primary">
              View Training Programs
            </a>
          </div>
          <div className="content-image2">
            <img
              src="/img/training_1.png"
              alt="OP-ST Training Session"/>
          </div>
        </div>
      </div>





    <div id="section-homemade-template-gr">
      <div className="container">
        <div className="row align-items-center align-items-stretch">
        <div className="col-12 col-md-6">
            <div className="about-image-container">
              <img 
                src="/img/training_2.png" 
                alt="About Us" 
                className="img-fluid about-image2" 
              />
            </div>
          </div>
          
        <div className="col-12 col-md-6">
            <div className="about-text">
              <h2>Why Train Your Employees on Rack Safety? </h2>
              <div className="list-style row">
                <div className="list-archetype">
                  <li><b>Reduced Damage
                  </b>: Protect your racking systems, inventory, and facility from preventable damage.</li><br/>
                  <li><b>Lower Injury Risks</b>: Minimize the chance of accidents, injuries, and fatalities.</li><br/>
                  <li><b>Boosted Productivity</b>: Keep operations running smoothly without interruptions.</li><br/>
                  <li><b>Controlled Costs</b>: Avoid increased insurance premiums and legal liabilities.</li><br/>
                  <li><b>Improved Workforce Morale</b>: Create a safer, more efficient workplace that retains top talent.</li>
                </div>

              </div>
            </div>
            
          </div>
          {/* Left Column: Image */}
          
          
        </div>
      </div>
    </div>


      {/* Benefits Section */}
      {/* <div className="expect-section">
        <div className="section-container">
          <div className="expect-content">
            <h2>Why Train Your Employees on Rack Safety?</h2>
            <p className="expect-intro">
              Pallet racks form the backbone of any warehouse, and ensuring the safety of your employees is just as critical. In high-traffic environments, such as manufacturing, retail, or cold storage, even minor damage to racking systems can escalate quickly, compromising both safety and operational efficiency.
            </p>
            <p className="expect-intro">
              Without proper training, employees may overlook critical issues, allowing damage to accumulate and threatening the integrity of your racking systems. The result? Costly repairs, operational downtime, and—most concerning—serious safety risks.
            </p>
            <p className="expect-intro-final">
              When employees are trained to recognize hazards, act swiftly, and follow safety protocols, the impact is immediate and significant:
            </p>


            <div className="expect-num-list">
              <div className="expect-num-list-item">
                <span className="to-expect-num">01</span>
                <h3>Reduced Damage</h3>
              </div>
              <p>Protect your racking systems, inventory, and facility from preventable damage.</p>
            </div>

            <div className="expect-num-list">
              <div className="expect-num-list-item">
                <span className="to-expect-num">02</span>
                <h3>Lower Injury Risks</h3>
              </div>
              <p>Minimize the chance of accidents, injuries, and fatalities.</p>
            </div>

            <div className="expect-num-list">
              <div className="expect-num-list-item">
                <span className="to-expect-num">03</span>
                <h3>Boosted Productivity</h3>
              </div>
              <p>Keep operations running smoothly without interruptions.</p>
            </div>

            <div className="expect-num-list">
              <div className="expect-num-list-item">
                <span className="to-expect-num">04</span>
                <h3>Controlled Costs</h3>
              </div>
              <p>Avoid increased insurance premiums and legal liabilities.</p>
            </div>

            <div className="expect-num-list">
              <div className="expect-num-list-item">
                <span className="to-expect-num">05</span>
                <h3>Improved Workforce Morale</h3>
              </div>
              <p>Create a safer, more efficient workplace that retains top talent.</p>
            </div>

            <p className="expect-num-list-quote">Investing in rack safety training isn’t just about compliance—it’s about safeguarding your people, your assets, and your bottom line.</p>
        </div>
      </div> */}

      {/* Training Programs Section */}
      <div id="training-programs" className="choose-us-section">
        <div className="section-container">
          <div className="choose-content">
            <h2>Training Programs</h2>
            <div className="training-grid">
              <TrainingCard
                title="1/2 DAY - VIRTUAL - PUBLIC SESSION"
                price="249$ CAD"
                includes="Includes: 1 seat"
                buttonLabel="Learn More"
                to="/first-training"
              />
              <TrainingCard
                title="1/4 DAY - VIRTUAL/ON-SITE - RACK AWARENESS"
                price="700$ CAD"
                includes="Includes: Up to 5 participants (100$ per additional attendee)"
                buttonLabel="Learn More"
                to="/second-training"
              />
              <TrainingCard
                title="1/2 DAY - VIRTUAL/ON-SITE - INSPECTING RACKS"
                price="1250$ CAD"
                includes="Includes: Up to 5 participants (200$ per additional attendee)"
                buttonLabel="Learn More"
                to="/third-training"
              />
              <TrainingCard
                title="FULL DAY - INSPECTING YOUR RACKING SYSTEMS"
                price="Contact for pricing"
                includes="Custom program for your facility"
                buttonLabel="Learn More"
                to="/fourth-training"
              />
            </div>
          </div>
        </div>
      </div>

      {/* FAQ Section */}
      <div className="faq-section">
        <div className="section-container">
          <h2>FAQ: Frequently Asked Questions</h2>
          <FAQItem
            question="What is covered during the OP-ST Training?"
            answer="Our expert trainers guide your team through the essential methods for conducting routine rack inspections, which are required in most regions. The training includes a deep dive into CSA Standard A344, key industry terminology, and critical legal considerations. By the end, your facility will be safer, and management will have minimized liability risks."
          />
          <FAQItem
            question="Who should attend the OP-ST Training?"
            answer="The training is designed for anyone responsible for rack safety, including warehouse employees, managers, maintenance staff, and forklift operators. It's perfect for those looking to enhance their expertise in maintaining safe and compliant racking systems."
          />
          <FAQItem
            question="In what language is the OP-ST Training offered?"
            answer="We offer OP-ST Training in both English and French, ensuring accessibility for your entire team."
          />
          <FAQItem
            question="Will my staff receive a certificate after completing the OP-ST Training?"
            answer="Absolutely! Every participant will receive a certificate upon completing the course, validating their expertise and commitment to rack safety."
          />
        </div>
      </div>
    </ServiceTemplate>
  );
};

export default TrainingPage;