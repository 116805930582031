import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import './components-css/contact.css';

const initialState = {
  name: "",
  email: "",
  message: "",
  subject: "",
};

export const Contact = (props) => {
  const [{ name, email, message, subject }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message, subject);
    alert("Your message has been sent!");

    emailjs
      .sendForm("service_nlh7tc8", "template_ee0cell", e.target, "0YBR_ldOXyc5utN0M")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="contact">
                <h2>Get In Touch</h2>
                <p className="please-fill-out">
                  Please fill out the form below to send us an email, and we will
                  get back to you as soon as possible.
                </p>
              </div>


              {/* FORM */}
              <form action="https://getform.io/f/bvrrljlb" method="POST" enctype="multipart/form-data">

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    className="form-control"
                    placeholder="Subject"
                    required
                    onChange={handleChange}
                  />
                  <p className="help-block text-danger"></p>
                </div>

                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>

                <div className="mb-3">
                  <input 
                    type="file" 
                    name="file"
                    className="file-button"
                  />
                </div>

                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>

              </form>
            </div>
          </div>



          {/* RIGHT SIDE CONTACT INFO (NOT THE FORM) */}
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>


          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul className="footerbuttons">
                  <li className="footerbuttons">
                    <a href="http://linkedin.com/company/optim-structure" target="_blank">
                      <i className="fa fa-linkedin"></i>
                    </a>
                    <li>
                    <a href="http://fr.optimstructure.com" target="_blank">
                    <img src="img/transfr.svg" alt="Select French Language" className="translogo" />
                    </a>
                  </li>
                  </li>
                </ul>
              </div>
            </div>

            
          </div>

          

        </div>
        
    


      </div>
      <div id="footer">
        <div className="container text-center">
          <p>&copy; {new Date().getFullYear()} OPTIM-STRUCTURE INC.</p>
        </div>
      </div>
    </div>
  );
};
