import React, { useState } from "react";
import ServiceTemplate from "../template/ServiceTemplate";
import "./css/capacitypage.css";


const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faq-item">
      <button 
        onClick={() => setIsOpen(!isOpen)}
        className="faq-question"
        aria-expanded={isOpen}
      >
        <span>{question}</span>
        <span className="faq-arrow">
          {isOpen ? "−" : "+"}
        </span>
      </button>
      {isOpen && (
        <div className="faq-answer">
          {answer}
        </div>
      )}
    </div>
  );
};

const CapacityPage = () => {
  return (
    <ServiceTemplate>
      {/* Title Section */}
      <div className="title-section">
        <h1>Capacity Reports</h1>
        <h2>Ensuring Safe and Compliant Racking Systems</h2>
      </div>

      {/* Content Section with Image */}
      <div className="content-section">
        <div className="content-wrapper">
          <div className="content-text">
            <p>
              In North America, every racking system must have documented load capacities to meet safety regulations. A Capacity Report, sealed by a Professional Engineer, is required before operating or reconfiguring racks. At Optim-Structure, we deliver precise load capacity calculations and certifications by expert engineers. Whether you’re adjusting beam heights or making structural changes, our reports ensure your racks are compliant, safe, and operational, minimizing risks and quickly avoiding costly downtime.

            </p>
            <button 
                className="btn-primary" 
                onClick={() => {
                  document.querySelector('#contact').scrollIntoView({ behavior: 'smooth' });
                }}
              >
                Request a Quote
              </button>
          </div>
          <div className="content-image">
            <img
              src="/img/capacity_1.jpg"
              alt="Capacity Report Service"
            />
          </div>
        </div>
      </div>


      <div id="section-homemade-template-gr">
      <div className="container">
        <div className="row align-items-center align-items-stretch">
          
          {/* Left Column: Image */}
          <div className="col-12 col-md-6">
            <div className="about-image-container">
              <img 
                src="/img/capacity_2.png" 
                alt="About Us" 
                className="img-fluid about-image2" 
              />
            </div>
          </div>
          {/* Right Column: About Us Text */}
          <div className="col-12 col-md-6">
            <div className="about-text">
              <h2>Why Are Capacity Reports Required?

              </h2>
              <div className="list-style row">
                <div className="list-archetype">
                  <p>Capacity Reports ensure your racking systems are compliant with safety regulations and protect against overloading. Certified by Professional Engineers, these reports evaluate structural integrity and calculate load capacities, essential for safe warehouse operations. They provide critical guidance during reconfigurations, ensuring every change is accounted for. Displaying accurate load limits keeps forklift operators informed, minimizing safety risks and downtime. With Optim-Structure, you get precise, reliable assessments that keep your racking systems safe, compliant, and ready for operational demands.</p>
                </div>

              </div>
            </div>
            
          </div>
          
        </div>
      </div>
    </div>


    <div id="section-homemade-template">
      <div className="container">
        <div className="row align-items-center align-items-stretch">
        <div className="col-12 col-md-6">
            <div className="about-text">
              <h2>What to Expect from Our Capacity Reports


              </h2>
              <div className="list-style row">
                <div className="list-archetype">
                  <li><b>Precise Warehouse Layout</b>: A precise warehouse layout of the targeted racking systems.</li><br/>
                  <li><b>Configuration Details</b>: Detailed information on your rack’s configurations and load capacities for each level of every bay.</li><br/>
                  <li><b>Compliant Signage
                  </b>: Issuance of capacity signs that display your racking systems' maximum weight capacities, compliant with CSA-A344 labelling standards.</li>
                </div>

              </div>
            </div>
            
          </div>
          {/* Left Column: Image */}
          <div className="col-12 col-md-6">
            <div className="about-image-container">
              <img 
                src="/img/capacity_3.png" 
                alt="About Us" 
                className="img-fluid about-image2" 
              />
            </div>
          </div>
          
          
        </div>
      </div>
    </div>


     {/* <div className="expect-section">
        <div className="section-container">
          <div className="choose-content">
            <h2>Why Are Capacity Reports Required?</h2>
          </div>
          


          <div className="inline_choose_content">
          <p>Capacity Reports ensure your racking systems are compliant with safety regulations and protect against overloading. Certified by Professional Engineers, these reports evaluate structural integrity and calculate load capacities, essential for safe warehouse operations. They provide critical guidance during reconfigurations, ensuring every change is accounted for. Displaying accurate load limits keeps forklift operators informed, minimizing safety risks and downtime. With Optim-Structure, you get precise, reliable assessments that keep your racking systems safe, compliant, and ready for operational demands.</p>

          
          </div>

        </div>
      </div>


      <div className="choose-us-section">
        <div className="section-container">
          <div className="expect-content">
            <h2>What to Expect from Our Capacity Reports</h2>
            <p className="expect-intro">
            Our Capacity Reports provide detailed evaluations to ensure your racking systems are safe, compliant, and optimized for your operational needs.
            </p>
            <div className="expect-num-list">
              <div className="expect-num-list-item">
                <span className="to-expect-num">01</span>
                <h3>Precise Warehouse Layout
                </h3>
              </div>
              <p>A precise warehouse layout of the targeted racking systems.
              </p>
            </div>

            <div className="expect-num-list">
              <div className="expect-num-list-item">
                <span className="to-expect-num">02</span>
                <h3> Configuration Details
                </h3>
              </div>
              <p>Detailed information on your rack’s configurations and load capacities for each level of every bay.</p>
            </div>

            <div className="expect-num-list">
              <div className="expect-num-list-item">
                <span className="to-expect-num">03</span>
                <h3> Compliant Signage
                </h3>
              </div>
              <p>Issuance of capacity signs that display your racking systems' maximum weight capacities, compliant with CSA-A344 labelling standards.</p>
            </div>

            

          </div>
        </div>
      </div> */}


      {/* FAQ Section */}
      <div className="faq-section">
        <div className="section-container">
          <h2>FAQ: Frequently Asked Questions</h2>
          <FAQItem
            question="Does my racking have to have a Capacity Report?"
            answer="Yes, all local governments require a Capacity Report. Employers must not load or operate racks until a Capacity Report has been completed and sealed by a Professional Engineer."
          />
          <FAQItem
            question="Which type of modifications will affect the capacity of a racking system?"
            answer="Changes, such as adjusting beam elevations, can affect the load capacity of beams, even in neighbouring bays. North American regulations require any racking changes to be reviewed by a specialist before reuse. Seemingly minor adjustments may compromise structural performance."
          />
          <FAQItem
            question="What about load capacity changes?"
            answer="As rack design codes evolve, older capacity charts may no longer be reliable, especially from defunct manufacturers. Updated charts provide purchasing guidelines, but a Professional Engineer must validate your racking to ensure compliance."
          />
          <FAQItem
            question="How much does a Capacity Report cost?"
            answer="The cost depends on racking types, elevations, and facility size. With a layout and photos, we can typically provide a quote within 24 hours."
          />
        </div>
      </div>
    </ServiceTemplate>
  );
};

export default CapacityPage;