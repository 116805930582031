import React from "react";
import ServiceTemplate from "../template/ServiceTemplate";
import "./css/styles.css";
import { Link } from "react-router-dom";
import "./css/FirstColumnPage.css";


const TrainingCardDetails = ({ title, price, includes, buttonLabel, to }) => (
  <div className="training-card-details">
    <div className="training-card-content">
      <div className="training-card-header">
        <h3>{title}</h3>
      </div>
      <div className="price-tag">{price}</div>
      <p className="includes">{includes}</p>
      <button className="btn-primary"
                  onClick={() => {
                    document.querySelector('#contact').scrollIntoView({ behavior: 'smooth' });
                  }}>
              Contact Us
            </button>

      
    </div>
  </div>
);


const ThirdColumnPage = () => {
  return (
    <ServiceTemplate>
      {/* Title Section */}
      <div className="title-section">
        <h1>1/2 DAY - VIRTUAL - PUBLIC SESSION</h1>
      </div>

      {/* Main Content Section */}
      <div id="section-homemade-template">
      <div className="container">
        <div className="row align-items-center align-items-stretch">
          
          {/* Left Column: Image */}
          <div className="col-12 col-md-6">
            <div className="training-details-card">
            <TrainingCardDetails
                title="FULL DAY - INSPECTING YOUR RACKING SYSTEMS"
                price="Contact for pricing"
                includes="Custom program for your facility"

                buttonLabel="Contact Us"
              />
            </div>
          </div>
          {/* Right Column: About Us Text */}
          <div className="col-12 col-md-6">
            <div className="about-text">
              <h3>The Full Day Session focuses on the following:</h3>
              <div className="list-style row">
                <div className="list-archetype">
                <div className="col-lg-12 col-sm-12">

                    <ul>
                      <li>Guides participants through the new CSA A344.24 User Guide for Steel Storage Racks</li>
                      <li>Provides knowledge and experience to inspect racks and recognize hazards</li>
                      <li>Aides attendees in gauging the severity of rack damage</li>
                      <li>Determine what to do before modifying racks or introducing new ones</li>
                      <li>Learn how to properly inspect and maintain steel storage racks</li>
                    </ul>
                  </div>
                </div>

              </div>
            </div>
            
          </div>
          
        </div>
      </div>
    </div>
    </ServiceTemplate>
  );
};

export default ThirdColumnPage;