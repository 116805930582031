const SmallBanner = () => {
    return (
        <div id='stats-banner'>
            <div className="stats-container">
                <div className="stat-item">
                    <span className="stat-number">12+</span>
                    <span className="stat-label">Years of Racking Expertise</span>
                </div>
                <div className="stat-item">
                    <span className="stat-number">5,000+</span>
                    <span className="stat-label">Rack Inspections Completed</span>
                </div>
                <div className="stat-item">
                    <span className="stat-number">3,000+</span>
                    <span className="stat-label">Capacity Reports Delivered</span>
                </div>
                <div className="stat-item">
                    <span className="stat-number">1M+</span>
                    <span className="stat-label">Hours of Downtime Prevented</span>
                </div>
            </div>
        </div>
    );
}
export default SmallBanner;