import React from "react";
import {Navigation} from "../components/navigation";
import {Contact} from "../components/contact";
import JsonData from "../data/data.json";
import { useEffect, useState } from "react";

const ServiceTemplate = ({ children }) => {
    const [templateData, setTemplateData] = useState({});
    useEffect(() => {
      setTemplateData(JsonData);
    }, []);
  return (
    <div>
      <Navigation data={templateData.Navigation} />
      <div className="container!">
        {children}
      </div>
      <Contact data = {templateData.Contact} />
    </div>
  );
};

export default ServiceTemplate;