import React, { useState } from "react";
import ServiceTemplate from "../template/ServiceTemplate";
import "./css/design.css";

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="faq-item">
      <button 
        onClick={() => setIsOpen(!isOpen)}
        className="faq-question"
        aria-expanded={isOpen}
      >
        <span>{question}</span>
        <span className="faq-arrow">
          {isOpen ? "−" : "+"}
        </span>
      </button>
      {isOpen && (
        <div className="faq-answer">
          {answer}
        </div>
      )}
    </div>
  );
};

const DesignPage = () => {
  return (
    <ServiceTemplate>
      {/* Title Section */}
      <div className="title-section">
        <h1>Warehouse Design</h1>
        <h2>Transforming Space into Performance</h2>
      </div>

      {/* Content Section */}
      <div className="content-section">
        <div className="content-wrapper">
          <div className="content-text">
            <p>
              At <b>Optim Structure</b>, we don't just design warehouses—we create spaces that drive productivity and efficiency for your specific business type. Our expert team leverages advanced techniques to optimize your layout, ensuring every square foot is used to its fullest potential. By improving workflow, maximizing storage, and prioritizing safety, we deliver a solution that not only meets your needs today but positions your business for long-term growth and success.
            </p>
            <button className="btn-primary"
                  onClick={() => {
                    document.querySelector('#contact').scrollIntoView({ behavior: 'smooth' });
                  }}>
              Request Consultation
            </button>
          </div>
          <div className="content-image2">
            <img
              src="/img/design_1.png"
              alt="Warehouse Design"
            />
          </div>
        </div>
      </div>
      <div id="section-homemade-template-gr">
      <div className="container">
        <div className="row align-items-center align-items-stretch">
          
          {/* Left Column: Image */}

          <div className="col-12 col-md-6">
            <div className="about-image-container">
              <img 
                src="/img/design_2.png" 
                alt="About Us" 
                className="img-fluid about-image2" 
              />
            </div>
          </div>
          {/* Right Column: About Us Text */}
          <div className="col-12 col-md-6">
            <div className="about-text">
              <h2>What to Expect from Our  Design Service</h2>
              {/* <p>At <b>Optim Structure</b>, we don’t just design your automated racking system; we partner with you to ensure it’s tailored to your exact needs. Here’s what you can expect from our racking automation service:</p> */}
              <div className="list-style row">
                <div className="list-archetype">
                  <ul>
                  <li><b>Warehouse Visit</b>: We begin with a thorough on-site assessment to understand your current space and identify key areas for improvement.</li><br/>
                  <li><b>Coordination Meetings</b>: We collaborate with you every step of the way, ensuring your vision is fully integrated into a functional, efficient warehouse plan.</li><br/>
                  <li><b>Warehouse Plan</b>: Our team develops a detailed, custom warehouse layout ready for approval and quote, with a focus on maximizing space and operational flow.</li><br/>
                  <li><b>Optional Quote</b>: If needed, we provide a comprehensive quote for the racking system and its installation, ensuring a seamless process from design to implementation.</li>
                </ul>
                </div>

              </div>
            </div>
            
          </div>

        </div>
      </div>
    </div>
    {/* <div className="expect-section">
      <div className="section-container">
        <div className="expect-content">
          <h2>What to Expect from Our Design Service</h2>
          
          <p className="expect-p">When you partner with <b>Optim Structure</b>, you gain access to our extensive industry experience and personalized approach. Here’s what the warehouse design process looks like:</p>
          <div className="expect-num-list">
            <div className="expect-num-list-item">
              <span className="to-expect-num">01</span>
              <h3>Warehouse Visit</h3>
            </div>
            <p>We begin with a thorough on-site assessment to understand your current space and identify key areas for improvement.</p>
          </div>

          <div className="expect-num-list">
            <div className="expect-num-list-item">
              <span className="to-expect-num">02</span>
              <h3>Coordination Meetings</h3>
            </div>
            <p>We collaborate with you every step of the way, ensuring your vision is fully integrated into a functional, efficient warehouse plan.</p>
          </div>

          <div className="expect-num-list">
            <div className="expect-num-list-item">
              <span className="to-expect-num">03</span>
              <h3>Warehouse Plan</h3>
            </div>
            <p>Our team develops a detailed, custom warehouse layout ready for approval and quote, with a focus on maximizing space and operational flow.</p>
          </div>

          <div className="expect-num-list">
            <div className="expect-num-list-item">
              <span className="to-expect-num">04</span>
              <h3>Optional Quote</h3>
            </div>
            <p className="design-final-expect">If needed, we provide a comprehensive quote for the racking system and its installation, ensuring a seamless process from design to implementation.</p>
          </div>


        </div>
      </div>
    </div> */}





      {/* FAQ Section */}
      <div className="faq-section">
        <div className="section-container">
          <h2>FAQ: Frequently Asked Questions</h2>
          <FAQItem
            question="How long does it take to design a customized warehouse layout?"
            answer="The timeline varies based on the complexity of your warehouse. We ensure every detail is covered to deliver the best possible solution."
          />
          <FAQItem
            question="How does Optim Structure ensure that the design maximizes space?"
            answer="With our expertise in space optimization, we balance storage capacity, safety, and operational flow using advanced tools and techniques, ensuring that no space is wasted and your operations run smoothly."
          />
          <FAQItem
            question="Do you handle the installation of the racking system as well?"
            answer="Yes, if needed, we can provide a full quote for the racking system and its installation, handling the entire process from design to execution."
          />
        </div>
      </div>
    </ServiceTemplate>
  );
};

export default DesignPage;
