import React from "react";
import ServiceTemplate from "../template/ServiceTemplate";
import "./css/styles.css";
import { InfiniteScrollBanner2 } from "../components/infinitescroll";


const AboutUsPage = () => {
  return (
    <ServiceTemplate>
      {/* Title Section */}
      <div className="title-section">
        <h1>About Us</h1>
        <h2>Delivering Excellence</h2>
      </div>

      {/* Main Content Section */}
      <div className="content-section">
        <div className="content-wrapper">
          <div className="content-text">
            <p>
              At <b>Optim Structure</b>, we pride ourselves on delivering engineering excellence in racking systems, warehouse design, and rack automation. With <b>over 12 years of industry experience</b>, we have built a reputation for optimizing warehouse operations to achieve the highest standards of efficiency, safety, and reliability.
            </p>
            <p>
              Our team of experts offers tailored solutions that go beyond the typical, ensuring every warehouse operates at its full potential.
            </p>
          </div>
          <div className="content-image8">
            <img
              src="/img/about_us_1.jpg"
              alt="Optim Structure Team"
            />
          </div>
        </div>
      </div>


      <div id="section-homemade-template-gr">
      <div className="container">
        <div className="row align-items-center align-items-stretch">
          
          {/* Left Column: Image */}

          <div className="col-12 col-md-6">
            <div className="about-image-container">
              <img 
                src="/img/about_us_2.png" 
                alt="About Us" 
                className="img-fluid about-image2" 
              />
            </div>
          </div>
          {/* Right Column: About Us Text */}
          <div className="col-12 col-md-6">
            <div className="about-text">
              <h2>What Sets Us Apart?</h2>
              {/* <p>At <b>Optim Structure</b>, we don’t just design your automated racking system; we partner with you to ensure it’s tailored to your exact needs. Here’s what you can expect from our racking automation service:</p> */}
              <div className="list-style row">
                <div className="list-archetype">
                  <p>Our expertise is not just local—it’s global. Drawing on <b>international experience</b> and a high standard of education and expertise, we bring world-class solutions to warehouses in <b>Québec</b> and <b>Ontario</b>, offering a level of service unmatched by manufacturers or local competitors. Many of these manufacturers lack the extensive hands-on knowledge we’ve acquired, and their solutions often fall short of providing the deep operational understanding required to truly optimize a warehouse.</p>
                  <p>At <b>Optim Structure</b>, we work alongside manufacturers, allowing us to gain a comprehensive and <b>broader perspective</b> on racking systems, ensuring our clients benefit from the latest innovations and practices. This unique insight gives us the ability to approach every project with a vast understanding of the systems at play, ensuring our recommendations are not just compliant but designed for superior performance.</p>
                </div>

              </div>
            </div>
            
          </div>

        </div>
      </div>
    </div>

    <div id="section-homemade-template">
      <div className="container">
        <div className="row align-items-center align-items-stretch">
          
          {/* Left Column: Image */}
          {/* Right Column: About Us Text */}
          <div className="col-12 col-md-6">
            <div className="about-text">
              <h2>A Global Standard in Every Project</h2>
              {/* <p>At <b>Optim Structure</b>, we don’t just design your automated racking system; we partner with you to ensure it’s tailored to your exact needs. Here’s what you can expect from our racking automation service:</p> */}
              <div className="list-style row">
                <div className="list-archetype">
                  <p>We deliver internationally renowned quality right to your doorstep, offering local warehouses the same premium service expected by top-tier companies around the globe. Whether it's a small business or a large-scale operation, our approach is always the same: <b>precision, innovation, and peace of mind</b>. When you partner with <b>Optim Structure</b>, you are choosing a team that strives for excellence in every aspect of your project.</p>
                  <p>Our clients trust us to not only meet industry standards but exceed them. By choosing Optim Structure, you’re choosing a partner who is committed to your long-term success. Your warehouse’s safety, efficiency, and operational reliability aren’t just goals for us—they are guarantees.</p>
                </div>

              </div>
            </div>
            
          </div>
          <div className="col-12 col-md-6">
            <div className="about-image-container">
              <img 
                src="/img/about_us_3.png" 
                alt="About Us" 
                className="img-fluid about-image2" 
              />
            </div>
          </div>
        </div>
      </div>
    </div>



      <InfiniteScrollBanner2 />


 

      {/* CTA Section */}
      <div className="practical-training-section">
        <div className="section-container">
          <div className="training-content">
            <div className="training-text">
              <h2>Choose Excellence with Optim Structure</h2>
              <div className="training-details">
                <p>
                  At Optim Structure, we know that when you choose to optimize your warehouse, you're not just choosing efficiency—you're choosing peace of mind, safety, and a team that delivers excellence, every single time.
                </p>
                <p>
                  Contact us below to learn more about how we can help you achieve your warehouse optimization goals.
                </p>
              </div>
            </div>
            <div className="training-cta">
              <button className="btn-training">
                Contact Us Today
                <span className="arrow-icon">→</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ServiceTemplate>
  );
};

export default AboutUsPage;
