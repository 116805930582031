import React from "react";
import { Link } from "react-router-dom"; // if you're using React Router

export const Services = (props) => {
  return (
    <section id="services" className="services-section">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
        </div>
        <div className="services-grid">
          {props.data ? (
            props.data.map((d, i) => (
              <Link
                to={d.link} // Use the link from data or fallback to a placeholder
                key={`${d.name}-${i}`}
                className="service-card"
              >
                <div>
                  <img
                    src={d.icon}
                    alt={d.name}
                    className="service-icon"
                  />
                  <div className="service-content">
                    <h3>{d.name}</h3>
                    <p dangerouslySetInnerHTML={{ __html: d.text }}></p>
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <p className="loading-text">Loading...</p>
          )}
        </div>
      </div>
    </section>
  );
};
