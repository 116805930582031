import React from "react";
import ServiceTemplate from "../template/ServiceTemplate";
import "./css/styles.css";
import { ReactComponent as ING } from '../svg/ING.svg';
import { ReactComponent as CSA } from '../svg/CSA.svg';


const CompliancePage = () => {
  return (
    <ServiceTemplate>
      {/* Title Section */}
      <div className="title-section">
        <h1>Compliance with CSA A344</h1>
        <h2>Your Path to Safe and Efficient Operations</h2>
      </div>

      {/* Content Section */}
      <div className="content-section">
        <div className="content-wrapper">
          <div className="content-text">
            <p>
              At Optim Structure, we believe that compliance is the foundation of a safe, efficient, and reliable warehouse operation. The CSA A344-24 Guideline is the recognized standard for steel storage racks in Canada, designed to ensure that your racking systems are installed, used, and maintained in a manner that prevents costly accidents, operational disruptions, and risks to employee safety.
            </p>
            <p>
            Whether you're running a large distribution center or a small warehouse, adhering to this guideline is not just a legal requirement—it’s an investment in your company’s future. Compliance with the CSA A344-24 not only protects your racking systems but also safeguards your employees, reduces liability, and helps maintain an efficient flow of operations.
            </p>
            <button className="btn-primary" onClick={() => {
                document.querySelector('#contact').scrollIntoView({ behavior: 'smooth' });
              }}>
              Contact Us for Compliance Support
            </button>
          </div>
          <div className="content-image3">
            <img
              src="/img/compliance_1.svg"
              alt="Compliance Services"
            />
          </div>


              
          
        </div>
      </div>

      <div id="section-homemade-template-gr">
      <div className="container">
        <div className="row align-items-center align-items-stretch">

        <div className="content-image3desktop">
            <img
              src="/img/compliance_2.svg"
              alt="Compliance Services"
            />
          </div>
        <div className="col-12 col-md-6">
            <div className="about-text">
              <h2>Why is Compliance with CSA A344 Essential?</h2>
              <div className="list-style row">
                <div className="list-archetype">
                  <p>Steel storage racks are the backbone of modern warehouses, but they can easily become a hazard if damaged or improperly used. Damage from forklifts, improper loading, or neglect can reduce rack capacity and, in worst-case scenarios, lead to catastrophic collapse. The consequences are severe and may include injury, product loss, and costly legal and reputational damages.</p>


                </div>

              </div>
            </div>
            
          </div>
          {/* Left Column: Image */}

          <div className="content-image3mobile">
            <img
              src="/img/compliance_2.svg"
              alt="Compliance Services"
            />
          </div>
          
        </div>
      </div>
    </div> <div id="section-homemade-template">
      <div className="container">
        <div className="row align-items-center align-items-stretch">
        <div className="col-12 col-md-6">
            <div className="about-text">

              <div className="list-style row">
                <div className="list-archetype">
                <p>According to the <b>CSA A344-24</b> standard, regular inspections, load assessments, and damage reporting are not only recommended—they're mandatory for safe operation. This guide emphasizes the importance of systematically identifying, recording, and correcting any issues, ensuring your racks maintain structural integrity over time.</p>

                <p><b><i>"Storage racks must be inspected regularly, and the frequency of inspections must be based on the environment, usage, and history of damage to the system"​</i></b>. This reinforces the need for ongoing vigilance and professional inspections to prevent hazards from escalating.</p>
                <p><b><i> "Owners are responsible for ensuring that the load on racks does not exceed the specified load limits."​</i></b> This is a key component of maintaining compliance and avoiding dangerous overload situations​.</p>
                </div>

              </div>
            </div>
            
          </div>
          {/* Left Column: Image */}
          <div className="content-image3">
            <img
              src="/img/compliance_3.svg"
              alt="Compliance Services"
            />
          </div>
          
          
        </div>
      </div>
    </div>


    <div id="section-homemade-template-gr">
      <div className="container">
        <div className="row align-items-center align-items-stretch">
        <div className="content-image3desktop">
            <img
              src="/img/compliance_5.jpg"
              alt="Compliance Services"
            />
          </div>
        <div className="col-12 col-md-6">
            <div className="about-text">
              <h2>Optim Structure Ensures Your Compliance</h2>
              <div className="list-style row">
                <div className="list-archetype">
                  <p>Our team of certified experts at Optim Structure takes the guesswork out of compliance. We work directly with your warehouse to ensure that your operations meet the stringent requirements of CSA A344-24. From thorough inspections and customized reports to advising on corrective actions, we help you navigate the complexities of compliance.<br/></p>

                </div>

              </div>
            </div>
            
          </div>
          {/* Left Column: Image */}
          <div className="content-image3mobile">
            <img
              src="/img/compliance_5.jpg"
              alt="Compliance Services"
            />
          </div>
        </div>
      </div>
    </div>




    <div id="section-homemade-template">
      <div className="container">
        <div className="row align-items-center align-items-stretch">

        <div className="col-12 col-md-6">
            <div className="about-text">

              <div className="list-style row">
                <div className="list-archetype">
                  <p>Here’s how we ensure your operations remain compliant:</p>
                  <li><b>Inspection and Reporting</b>: We carry out comprehensive assessments to identify any damage, overload risks, or non-compliance with the CSA A344 standard. We then provide you with a clear, actionable report to address these issues promptly.</li><br/>
                  <li><b>Damage Classification and Repair Guidance</b>: As required by CSA A344, we classify damage severity and recommend immediate repairs or replacements to prevent collapse.</li><br/>
                  <li><b>Ongoing Support</b>: Compliance is not a one-time task. We offer ongoing support, training, and regular inspections to ensure your systems stay compliant year-round.</li>

                </div>

              </div>
            </div>
            
          </div>
          {/* Left Column: Image */}
          <div className="content-image3">
            <img
              src="/img/compliance_4.png"
              alt="Compliance Services"
            />
          </div>
        </div>
      </div>
    </div>



      {/* Call to Action Section */}
      <div className="practical-training-section">
        <div className="section-container">
          <div className="training-content">
            <div className="training-text">
              <h2>Make Compliance Your Competitive Edge</h2>
              <div className="training-details">
                <p>
                  "Failure to adhere to rack safety standards can result in fines, operational delays, or even prosecution." Compliance isn't just about ticking a box—it's about protecting your business from costly legal repercussions while creating a safer and more efficient workplace.
                </p>
                <p>
                  Ensure your business operates safely and smoothly—get in touch today to learn how Optim Structure can make compliance simple and effective for your operation.
                </p>
                <p>
                  Contact us below to learn more about how we can help you achieve your warehouse optimization goals.
                </p>
              </div>
            </div>
            <div className="training-cta">
              <button className="btn-training" onClick={() => {
                document.querySelector('#contact').scrollIntoView({ behavior: 'smooth' });
              }}>
                Contact Us Today
                <span className="arrow-icon">→</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ServiceTemplate>
  );
};

export default CompliancePage;
