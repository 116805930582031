import React from "react";
import { ReactComponent as ING } from '../svg/ING.svg';
import { ReactComponent as CSA } from '../svg/CSA.svg';
import { Link } from "react-router-dom";
import './components-css/compliance.css';

export const Compliance = (props) => {
  return (
    <div id="compliance">
      <div className="container">
        <div className="row">

          {/* Left Column: Compliance Text */}
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Compliance</h2>
              <p>{props.data ? props.data.compliance1 : "loading..."}</p>
              <p>{props.data ? props.data.compliance2 : "loading..."}</p>
              <div>
                <Link to="/compliance" className="btn btn-custom btn-lg">
                  Learn More
                </Link>
                
              </div>
            </div>
          </div>

          {/* Right Column: Logos */}
          <div className="main-comp-logo col-md-12-col-md-6">
            <div className="logos-container">
              <div className="logo-box">
                <ING className="ing-logo" />
              </div>
              <div className="logo-box">
                <CSA className="csa-logo" />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};